<template>
  <div :class="`loader-${type}`">
    <div
      class="loader-inner-button"
      :class="color ? 'loader-inner-button_' + color : ''"
    />
  </div>
</template>

<script>
export default {
  name: "ButtonLoader",
  props: {
    type: {
      type: String,
      default: "button",
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/mixins/spinner";

.loader {
  &-button {
    min-height: 16px;
    min-width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader-inner-button {
      @include spinner(12px, #fff);
    }
  }
}
</style>
